import classnames from 'classnames';
import { useRouter } from 'next/dist/client/router';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useState } from 'react';

import { Breadcrumb } from '@jernia/shared/components/breadcrumbs/breadcrumbs';
import {
  BarcodeScannerEanIcon,
  MenuHamburgerIcon,
  ShoppingCartIcon,
} from '@jernia/shared/components/icons/icons';
import { RoundedButton } from '@jernia/shared/components/rounded-button/rounded-button';
import { Toaster } from '@jernia/shared/components/toaster/toaster';

import { useChangeStoreMutation } from '@jernia/shared/state/auth';
import { useCart } from '@jernia/shared/state/cart/queries';

import { Logo } from '../logo/logo';
import { StoreSelectModal } from '../modal/store-select-modal';
import Footer from './footer';
import Header from './header';
import { InstantSearchContainer } from './instant-search-container';
import { MainMenu } from './main-menu';

const BarcodeScanner = dynamic<any>(
  () =>
    import('../barcode-scanner/barcode-scanner').then(
      (mod) => mod.BarcodeScanner
    ),
  { ssr: false }
);

type Props = {
  children: React.ReactNode;
  showSearchField?: boolean;
  greyBackground?: boolean;
  title?: string;
  breadcrumbs?: Breadcrumb[];
  algoliaRouting?: any;
  className?: string;
};

function Cart() {
  const { data: cart } = useCart();
  const router = useRouter();
  const amount = cart?.totalItems ?? 0;

  return (
    <button
      onClick={() => router.push('/cart')}
      className={classnames(
        '-my-5 -mr-4 flex items-center space-x-4 pr-4 focus:outline-none',
        {
          'md:bg-success md:pl-4': amount > 0,
        }
      )}
    >
      <RoundedButton
        noButton
        className="relative text-white focus:ring"
        color={amount > 0 ? 'success-800' : 'primary'}
      >
        {amount > 0 && (
          <span className="absolute -left-1 -top-1 h-4.5 min-w-[18px] rounded-full bg-danger p-0.5 text-center text-[11px] font-semibold leading-none">
            {amount}
          </span>
        )}
        <ShoppingCartIcon className="md:size-6" />
      </RoundedButton>
      {amount > 0 && (
        <div className="hidden text-white md:block">
          <div>Handlekurv</div>
          <div className="text-xl font-medium">
            {cart?.totalPrice.value ?? 0} kr
          </div>
        </div>
      )}
    </button>
  );
}

export default function Layout({
  children,
  showSearchField,
  greyBackground,
  title,
  breadcrumbs,
  algoliaRouting,
  className,
}: Props) {
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  const [showStoreSelectModal, setShowStoreSelectModal] = useState(false);
  const changeStoreMutation = useChangeStoreMutation();

  return (
    <div
      className={classnames('flex min-h-screen flex-col', className, {
        'bg-neutral-300': greyBackground,
      })}
    >
      <header className="bg-primary-800">
        <div className="relative mx-auto flex items-center justify-center px-4 py-5">
          <Link href="/" className="order-1 max-h-full sm:absolute sm:order-3">
            <Logo className="h-8 max-h-full w-full" />
          </Link>

          <div className="order-2 flex-1" />

          <RoundedButton
            className="order-3 mr-2 text-white sm:order-1 sm:mr-auto"
            onClick={() => {
              setIsMainMenuOpen(true);
            }}
          >
            <MenuHamburgerIcon className="md:size-6" />
          </RoundedButton>

          <div className="order-3 ml-auto flex h-full">
            <div className="mr-2 items-center sm:mr-4">
              <RoundedButton
                onClick={() => setShowBarcodeScanner(true)}
                className="text-white"
              >
                <BarcodeScannerEanIcon className="md:size-6" />
              </RoundedButton>
            </div>
            <Cart />
          </div>
        </div>
      </header>

      <InstantSearchContainer routing={algoliaRouting}>
        {breadcrumbs?.length || title || showSearchField ? (
          <Header breadcrumbs={breadcrumbs} title={title} showSearchField />
        ) : null}

        {showBarcodeScanner && (
          <BarcodeScanner
            isOpen={showBarcodeScanner}
            onClose={() => setShowBarcodeScanner(false)}
          />
        )}

        <main className="relative grow">{children}</main>
      </InstantSearchContainer>

      <MainMenu
        isOpen={isMainMenuOpen}
        onClose={() => setIsMainMenuOpen(false)}
        onChangeStore={() => setShowStoreSelectModal(true)}
      />

      <StoreSelectModal
        isOpen={showStoreSelectModal}
        onChange={async (store) => {
          await changeStoreMutation.mutateAsync({ storeId: store.name });
          setShowStoreSelectModal(false);
        }}
        onClose={() => setShowStoreSelectModal(false)}
      />

      <Toaster className="hub-toaster" />

      <Footer />
    </div>
  );
}
